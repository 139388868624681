.notification-wrapper .boxs .seen-notification {
    border-radius: 10px !important;
    border: 1px solid rgba(13, 122, 95, 0.90) !important;
    background: #F5F7F6 !important;
}

.notification-wrapper .boxs .box {
    border-radius: 8px;
    border: 0.8px solid rgba(13, 122, 95, 0.20);
    background: #FFF;
    cursor: pointer;
}

.notification-wrapper img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.notification-wrapper h3 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.notification-wrapper h6 {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.6;
    white-space: nowrap;
}

.notification-wrapper .pagination .arrow-btn img {
    width: 24px;
    height: 24px;
}

.notification-wrapper .pagination .page-link {
    z-index: 3;
    background-color: none;
    border: none;
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: 500;
    border-radius: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.notification-wrapper .pagination .active .page-link {
    color: #fff;
    background-color: #0D7A5F;
}

.notification-wrapper .btn-outline-success {
    border: 1px solid #0D7A5F;
    color: #0D7A5F;
    transition: all 0.3s;
    font-size: 18px;
    font-weight: 500;
}

.notification-wrapper .btn-outline-success:hover {
    border: 1px solid #0D7A5F;
    color: #fff;
    background-color: #0D7A5F;
}

.notification-modal h1 {
    color: #000;
    font-size: 30px;
    font-weight: 600;
}

.notification-modal img {
    width: 100px;
    height: 100px;
}

.notification-modal p {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.notification-modal .star-icon {
    height: 41px;
    width: 41px;
}

.notification-modal textarea {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #FFF;
}

@media only screen and (max-width: 575px) {
    .notification-wrapper h6 {
        margin-left: 75px;
    }
}