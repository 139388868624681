.filter-modal .suggestions-container {
    background: #f2f2f2;
    position: absolute;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 999;
    cursor: pointer;
    color: #000;
}

.filter-modal .suggestion-item {
    padding: 8px;
    cursor: pointer;
}

.filter-modal .suggestion-item:hover {
    background-color: #D3D6DB;
}

.filter-modal .suggestion-text {
    font-size: 14px;
    color: #333;
}