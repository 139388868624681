.availability-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.availability-wrapper .small-box {
    border-radius: 10px;
    border: 1px solid rgba(13, 122, 95, 0.20);
    background: #FFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
}

.availability-wrapper .form-check-label {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.availability-wrapper .grid-3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}