.plumbing-detail-card {
    border-radius: 20px;
    border: 1px solid #0D7A5F;
    background: #FFF;
}

.plumbing-detail-card .card-header {
    border-radius: 20px 20px 0px 0px;
    background: #F6F6F6;
}

.plumbing-detail-card h2 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
}

.plumbing-detail-card .person {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.plumbing-detail-card h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.plumbing-detail-card h6 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background: rgba(13, 122, 95, 0.10);
    padding: 3px 8px;
}

.plumbing-detail-card span {
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.plumbing-detail-card .icon {
    width: 13.771px;
    height: 13.2px;
}

.plumbing-detail-card .icons {
    width: 20px;
    height: 20px;
}

.plumbing-detail-card .change-btn {
    color: #0D7A5F;
    font-size: 16px;
    border: none;
    background: none;
    font-weight: 500;
}

.plumbing-detail-card p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.plumbing-detail-card .paymentCard h6 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
    background: none;
}

.plumbing-detail-card .paymentCard .price {
    color: #0D7A5F;
    font-size: 18px;
    font-weight: 600;
}

.plumbing-detail-card .myTask-onGoing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.plumbing-detail-card .chat {
    width: 24px;
    height: 24px;
}

.plumbing-detail-card h4 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 500;
}

.plumbing-detail-card h4 span {
    color: #000;
    font-size: 20px;
    font-weight: 600;
}

.plumbing-detail-card .add {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
    background-color: #fff;
    border: none;
}

.plumbing-detail-card button,
.plumbing-detail-card .bold {
    font-weight: 600;
}