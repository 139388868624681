.languages-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.languages-wrapper .flag {
    width: 31px;
    height: 16px;
}

.languages-wrapper h6 {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    opacity: 0.6;
    cursor: pointer;
    margin-bottom: 0;
}

.languages-wrapper hr {
    height: 1px;
    background-color: #252525;
    max-width: 500px;
    width: 100%;
}

.languages-wrapper .height {
    height: 100%;
    min-height: 593px;
    display: grid;
    place-items: center;
}

.languages-wrapper .currect {
    width: 24px;
    height: 24px;
}

.languages-wrapper .selected-language {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.8;
    margin-bottom: 0;
}

.languages-wrapper .unselected-language {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    opacity: 0.6;
}