.rating-review-wrapper .star-icon {
    height: 25px;
    width: 25px;
}

.rating-review-wrapper p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
}

.rating-review-wrapper .rating {
    color: #000;
    font-size: 24px;
    font-weight: 600;
}

.rating-review-wrapper .grid-3 {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .rating-review-wrapper .grid-3 {
        grid-template-columns: 1fr 4fr 1fr;
    }
}


@media only screen and (max-width: 576px) {
    .rating-review-wrapper .remove>img {
        display: none;
    }

    .rating-review-wrapper .grid-3 {
        grid-template-columns: 1fr 7fr 4fr;
    }
}

.rating-review-wrapper .arrow-page {
    width: 24px;
    height: 24px;
}

.rating-review-wrapper .pagination-btn {
    width: 30px;
    height: 30px;
    border: none;
    background: #fff;
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
    font-weight: 500;
    border-radius: 5px;
}

.rating-review-wrapper .active {
    background: #0D7A5F;
    color: #fff;
}

.rating-review-wrapper .pagination {
    justify-content: center;
    gap: 10px;
}