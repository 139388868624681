.choose-section .box {
    max-width: 560px;
}

.edit-icon {
    height: 24px;
    width: 24px;
}

.character {
    border: none;
    font-size: 20px;
    border-radius: 8px;
    border-radius: 10px;
    border: 2px solid rgba(214, 214, 214, 0.90);
    background: #FFF;
    color: #000;
    width: 83px;
    height: 50px;
}

.vi__wrapper {
    width: 100%;
}

.character--selected {
    outline: none;
    color: #000;
}

.vi_container {
    display: grid;
    grid-template-columns: repeat(4, auto);
    width: 100%;
    justify-content: space-between;
}

.character--selected {
    position: relative;
}

.character--selected:empty::after {
    content: "";
    width: 1px;
    height: 22px;
    background-color: #333;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blink 1s infinite step-end;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}


@media only screen and (max-width: 576px) {
    .character {
        width: 50px;
        height: 100%;
    }
}