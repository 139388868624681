.post-task-card .task-posting-tag,
.post-task-card p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.7;
    margin-bottom: 0;
}

.post-task-card .desc {
    overflow: hidden;
    display: -webkit-box;
    line-height: 1.4;
    height: 100px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
}

.post-task-card h3 {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}

.post-task-card .card {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    height: 100%;
}

.taskbid-wrapper .card h3 {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
}

.taskbid-wrapper .card p {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.6;
}

.taskbid-wrapper .card .desc {
    overflow: hidden;
    display: -webkit-box;
    line-height: 1.4;
    max-height: 100px;
    word-break: break-all;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.taskbid-wrapper .card .bids p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.taskbid-wrapper .card .bids span {
    color: #0D7A5F;
    font-size: 22px;
    font-weight: 600;
    opacity: 1 !important;
}



.taskbid-wrapper .card {
    height: fit-content;
}