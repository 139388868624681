.choose-section .box {
    max-width: 560px;
}

.character {
    border: none;
    font-size: 20px;
    border-radius: 8px;
    border-radius: 10px;
    border: 2px solid rgba(214, 214, 214, 0.90);
    background: #FFF;
    color: #000;
    width: 83px;
    height: 50px;
}

.vi__wrapper {
    width: 100%;
}

.character--selected {
    outline: none;
    color: #000;
}

.vi_container {
    display: grid;
    grid-template-columns: repeat(4, auto);
    width: 100%;
    justify-content: space-between;
}

@media only screen and (max-width: 576px) {
    .character {
        width: 50px;
        height: 100%;
    }
}