@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none !important;
}

html {
    height: 100%;
    width: 100%;
}

body {
    font-family: 'Inter', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.flex-1 {
    flex: 1;
}

#root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1 {
    color: #000;
    font-size: 30px;
    font-weight: 600;
}

.coman_h2 {
    font-size: 28px;
    font-weight: 600;
}

.coman-btn {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #fff;
    color: #0D7A5F;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coman-btn-fill {
    border-radius: 10px;
    background: #0D7A5F;
    border: 1px solid #0D7A5F;
    color: #fff;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-button-prev,
.swiper-button-next {
    z-index: 99;
}

.swiper-pagination-bullet {
    background-color: #0D7A5F;
}

.swiper-pagination-bullet-active {
    color: #0D7A5F !important;
}

.form-control,
.form-select {
    border-radius: 10px;
    border: 2px solid rgba(214, 214, 214, 0.90) !important;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.7;
    color: #000;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none;
}

input:focus,
textarea:focus {
    outline: none;
}

.modal-header .btn-close {
    position: absolute;
    right: 20px;
    top: 20px;
}

.modal-header .btn-close:focus {
    box-shadow: none;
}

.bg-image {
    min-height: calc(100vh - 70px);
}

.small-icon-img {
    height: 24px;
    width: 24px;
}

.pagination-wrapper button:focus {
    box-shadow: none;
}

.pagination-wrapper .page-link {
    border: none;
}

.pagination-wrapper .page-link {
    color: rgb(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
}

.pagination-wrapper .pagination .active .page-link {
    background-color: #0D7A5F !important;
    border-radius: 8px;
    color: #fff;
}

.pagination-wrapper .disabled {
    opacity: 0.3;
}

.ReactFlagsSelect-module_selectOptions__3LNBJ {
    width: 150px;
}

body::-webkit-scrollbar,
.ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar {
    width: 5px;
}

body::-webkit-scrollbar-track,
.ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb,
.ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.Toastify__toast-container .Toastify__progress-bar--success {
    background: #0D7A5F;
}

.Toastify__toast-container .Toastify__toast--success svg {
    width: 100%;
    height: 100%;
    fill: #0D7A5F;
}

.small-address {
    overflow: hidden;
    display: -webkit-box;
    line-height: 1.4;
    max-height: 42px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.dropdown-height {
    max-height: 600px;
    overflow-y: auto;
}

.dropdown-height::-webkit-scrollbar {
    width: 0px;
}

.btn-success,
.bg-success {
    background-color: #0D7A5F;
    color: #fff;
}

.privacy-policy-container li {
    color: #252525;
    font-size: 16px;
    font-weight: 400;
}

.privacy-policy-container ul {
    list-style: disc;
}

.custome_search input {
    border: 1px solid #0D7A5F33;
    height: 50px;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: 0 10px;
}

.custome_search ul {
    background-color: #F6F6F6;
    list-style: none;
    line-height: 2;
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    border-radius: 10px;
    z-index: 998;
}

.custome_search li {
    cursor: pointer;
}

.custome_search ul li .services-name {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #000;
    display: block;
    padding: 10px 20px;
}

.custome_search li:hover {
    background-color: #D3D6DB;
}

.custome_search ul::-webkit-scrollbar {
    width: 5px;
}

.custome_search ul::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.custome_search ul::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.custome_search .close-icon {
    position: absolute;
    right: 10px;
    top: 12px;
    width: 24px !important;
    height: 24px !important;
}

.three-month-trial-model .modal-content {
    padding: 30px;
}

@media only screen and (max-width: 576px) {
    .three-month-trial-model .modal-content {
        padding: 10px;
    }
}