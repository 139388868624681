.earning-wrapper .header {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.earning-wrapper .header h1 {
    color: #000;
    font-size: 34px;
    font-weight: 500;
    opacity: 0.8;
}

.earning-wrapper .header h2 {
    color: #0D7A5F;
    font-size: 34px;
    font-weight: 600;
}

.earning-wrapper h5 {
    color: #000;
    font-size: 22px;
    font-weight: 500;
    opacity: 0.8;
}

.earning-wrapper h6 {
    color: #0D7A5F;
    font-size: 28px;
    font-weight: 600;
}

.earning-wrapper .grid-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.earning-wrapper .box {
    border-radius: 8px;
    border: 0.8px solid rgba(13, 122, 95, 0.20);
    background: #FFF;
}

.earning-wrapper .wallet-box {
    border-radius: 8px;
    border: 0.8px solid rgba(13, 122, 95, 0.20);
    background: #FFF;
}

.earning-wrapper .wallet-box img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.earning-wrapper .wallet-box h3 {
    color: #000;
    font-size: 26px;
    font-weight: 600;
}

.earning-wrapper .wallet-box span {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
}

.earning-wrapper .wallet-box h2 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 500;
}

.earning-wrapper .wallet-box h4 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}


.earning-wrapper .box h3 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.earning-wrapper .box p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    opacity: 0.6;
}

.earning-wrapper .box h4 {
    color: #0D7A5F;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .earning-wrapper .grid-3 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 576px) {
    .earning-wrapper .grid-3 {
        grid-template-columns: repeat(1, 1fr);
    }

    .earning-wrapper .header h1 {
        color: #000;
        font-size: 26px;
        font-weight: 500;
        opacity: 0.8;
    }

    .earning-wrapper .header h2 {
        color: #0D7A5F;
        font-size: 26px;
        font-weight: 600;
    }
}