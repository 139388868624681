.choose-section .box {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.25);
    width: 646px;
    max-width: 646px;
}

.choose-section .icon {
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    border: 0.5px solid rgba(15, 44, 150, 0.80);
    padding: 7px;
}

.choose-section h1 {
    color: #000;
    font-size: 36px;
    font-weight: 600;
}

.choose-section p {
    color: rgba(0, 0, 0, 0.60);
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

.choose-section p .links {
    color: #0D7A5F;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-decoration: underline !important;
    text-underline-position: under;
}

.choose-section h6 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 14px;
    font-weight: 500;
}

.choose-section h6 .links {
    color: #0D7A5F;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline !important;
    text-underline-position: under;
}

.links {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
}

.choose-section .eye-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 13px;
    right: 13px;
}

.choose-section .underline {
    border-bottom: 1px solid #0D7A5F;
}


.choose-section .menu-flags {
    top: 0;
}

.choose-section .menu-flags button {
    border: 0;
    height: 50px;
}

.choose-section .suggestions-container {
    background: #f2f2f2;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    cursor: pointer;
    color: #000;
}

.choose-section .suggestion-item {
    padding: 8px;
    cursor: pointer;
}

.choose-section .suggestion-item:hover,
.choose-section .suggestion-item:focus,
.choose-section .suggestion-item.selected {
    background-color: #D3D6DB;
}

.choose-section .suggestion-text {
    font-size: 14px;
    color: #333;
}

.choose-section .suggestions-container::-webkit-scrollbar {
    width: 5px;
}

.choose-section .suggestions-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.choose-section .suggestions-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}