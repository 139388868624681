.taskAndPriceDetailModal h1 {
    color: #000;
    font-size: 26px;
    font-weight: 600;
}

.taskAndPriceDetailModal h6 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.taskAndPriceDetailModal h2 {
    color: #0D7A5F;
    font-size: 24px;
    font-weight: 600;
}

.taskAndPriceDetailModal .span {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background: rgba(13, 122, 95, 0.10);
}

.taskAndPriceDetailModal .profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.taskAndPriceDetailModal .star {
    height: 16px;
    width: 16px;
}

.taskAndPriceDetailModal h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    text-align: start;
}

.taskAndPriceDetailModal p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: start;
}

.taskAndPriceDetailModal .price {
    color: #0D7A5F;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    background: transparent;
}