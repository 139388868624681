.footer-link {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.6;
}

footer h1 {
    color: #000;
    font-size: 40px;
    font-weight: 600;
}

footer h2 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 15px;
    font-weight: 400;
}

footer span {
    color: #000;
    font-size: 15px;
    font-weight: 600;
}

.footer-copyright {
    color: #000;
    font-size: 14px;
    font-weight: 400;
}

.footer-logo {
    height: 48px;
}