.accordion-button:not(.collapsed) {
    background-color: white;
    box-shadow: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion button {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.accordion .accordion-body {
    color: #252525;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.4;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
}