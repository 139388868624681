.customer-support-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.customer-support-wrapper label {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}