.card {
    height: 100%;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.card-imgs {
    height: 180px;
    object-fit: cover;
    border-radius: 10px;
    /* width: 100%; */
}

.tag-icon {
    width: 24px;
    height: 24px;
}

.card-title {
    color: #0D7A5F;
    font-size: 20px;
    font-weight: 500;
}

.text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 400;
}

.text span {
    color: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: 500;
}