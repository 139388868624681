.navbar {
    background: #FFF;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;
    z-index: 999;
}

.logout-img {
    height: 30px;
    width: 30px;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler-icon {
    background-image: url("../../assets/images/menu.png");
}

.navbar-brand {
    color: #000;
    font-size: 40px;
    font-weight: 600;
}

.navbar-brand img {
    height: 36px;
}

.navbar .active {
    font-weight: 500;
    opacity: 1;
    border-bottom: 2px solid #0D7A5F;
}

.nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.6;
    width: fit-content;
}

.nav-link-box {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: rgba(13, 122, 95, 0.10);
}

.nav-link-box .nav-link {
    font-weight: 500;
    color: #000;
    font-size: 18px;
    opacity: 1;
}

.navbar-nav .dropdown .dropdown-item {
    background-color: #fff;
    color: #000;
}

.navbar-nav .dropdown .nav-link {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
}

.navbar-nav .person-img {
    width: 65.563px;
    height: 65.563px;
    border-radius: 50%;
    object-fit: cover;
}

.navbar-nav .dropdown h3 {
    color: #000;
    font-size: 22px;
    font-weight: 600;
}

.navbar-nav .dropdown p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.6;
}

.navbar-nav .dropdown .icon {
    width: 20px;
    height: 20px;
}

.navbar-nav .dropdown h4 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.8;
}

.navbar-nav .dropdown .footer {
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-weight: 400;
}

.navbar-nav .dropdown .footer span {
    color: #000;
    font-size: 12px;
    font-weight: 700;
}

.navbar-nav .dropdown .dropdown-menu {
    border-radius: 20px;
}

.navbar-nav .dropdown .nav-profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.main-navbar .nav-icon {
    height: 24px;
    width: 24px;
}

.main-navbar .nav-link-icon {
    height: 20px;
    width: 20px;
}

.main-navbar p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.6;
}

.main-navbar .active p {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
}

.main-navbar .active {
    border-bottom: 2px solid #0D7A5F;
    padding-bottom: 3px;
    width: fit-content;
}

.main-navbar .active .nav-link-icon,
.main-navbar .active .nav-img-icon {
    filter: brightness(0) saturate(100%) invert(33%) sepia(91%) saturate(396%) hue-rotate(115deg) brightness(95%) contrast(95%);
}

.main-navbar .bell {
    position: absolute;
    right: -1px;
    top: -2px;
    height: 11px;
    width: 11px;
    background: red;
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
}

.navbar-nav .dropdown .star-icon {
    width: 13px;
    height: 13px;
}

.navbar-nav .wallet-box {
    border-radius: 10px !important;
    background: #0D7A5F !important;
}

.navbar-nav .wallet-box h2 {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
}

.navbar-nav .wallet-box span {
    color: #0D7A5F;
    font-size: 18px;
    font-weight: 600;
    border-radius: 6px;
    background: #FFF;
    width: 156px;
}

.dropdown .dropdowns-list {
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
}

.dropdown .dropdowns-list img {
    width: 17px;
    height: auto;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(340deg) brightness(105%) contrast(103%);
}

.language-dropdown .btn {
    color: #0D7A5F;
}

.language-dropdown .icon {
    height: 18px;
    width: 18px;
}