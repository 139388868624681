.edit-profile-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.edit-profile-wrapper label {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.edit-profile-wrapper .profile-photo {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
}

.edit-profile-wrapper .edit-icon {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #0D7A5F;
    border-radius: 50%;
    right: 8px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.edit-profile-wrapper .edit-icon img {
    width: 16px;
    height: 16px;
}

.edit-profile-wrapper .upload-label {
    color: #151515;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.42px;
}

.edit-profile-wrapper .lh-lg {
    line-height: 4 !important;
}

.edit-profile-wrapper .menu-flags {
    top: 0;
}

.edit-profile-wrapper .menu-flags button {
    border: none;
    height: 50px;
}

.edit-profile-wrapper .suggestions-container {
    background: #f2f2f2;
    border-radius: 10px;
    max-height: 200px;
    overflow-y: auto;
    cursor: pointer;
    color: #000;
}

.edit-profile-wrapper .suggestion-item {
    padding: 8px;
    cursor: pointer;
}

.edit-profile-wrapper .suggestion-item:hover,
.edit-profile-wrapper .suggestion-item:focus,
.edit-profile-wrapper .suggestion-item.selected {
    background-color: #D3D6DB;
}

.edit-profile-wrapper .suggestion-text {
    font-size: 14px;
    color: #333;
}

.edit-profile-wrapper .suggestions-container::-webkit-scrollbar {
    width: 5px;
}

.edit-profile-wrapper .suggestions-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.edit-profile-wrapper .suggestions-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}