.task-acceptcard {
    border-radius: 20px;
    border: 1px solid var(--green, #0D7A5F);
    background: #FFF;
}

.task-acceptcard .profile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.task-acceptcard h3 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.9;
}

.task-acceptcard h2 {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 600;
}

.task-acceptcard p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 500;
}

.task-acceptcard .icon {
    width: 20px;
    height: 20px;
}

.task-acceptcard h4 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.task-acceptcard h5 {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 600;
}