.payment-details .description,
.payment-details .price-details {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #FFF;
}

.payment-details .description p {
    border-radius: 10px;
    background: #F6F6F6;
}

.payment-details h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.payment-details h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.payment-details p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.payment-details .payment,
.payment-details .promo,
.payment-details .myTaskDetailsCredit {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.payment-details .option {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
    height: 50px;
}

.payment-details input[type='radio'] {
    accent-color: #0D7A5F;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.payment-details .icon {
    width: 18px;
    height: 18px;
    outline: 1px solid rgba(0, 0, 0, 0.20);
    border-radius: 3px;
    outline-offset: 3px;
}

.payment-details .cash-icon {
    width: 40px;
    height: 26px;
}

.payment-details .paypal-icon {
    width: 40px;
    height: 26px;
}


.payment-details input,
.payment-details select {
    border-radius: 10px;
    border: 1px solid rgba(13, 122, 95, 0.20) !important;
    width: 100%;
    padding: 0 20px;
    height: 60px;
}

.payment-details .promo img {
    width: 12px;
    height: 8px;
}

.payment-details .promo input {
    padding-right: 100px;
}

.payment-details .promo span {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
}

.payment-details .promo .apply {
    right: 17px;
    top: 17px;
}

.payment-details .bg-green {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.payment-details .bg-green .badge {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #FFF;
}

.payment-details .price-details h4 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.6;
}

.payment-details .price-details h5 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.8;
    text-align: right;
}

.payment-details .price-details span {
    color: #0D7A5F;
    font-size: 18px;
    font-weight: 500;
    text-align: right;
}

.payment-details .price-details h2 {
    color: #000;
    font-size: 19px;
    font-weight: 500;
}

.payment-details .price-details h1 {
    color: #000;
    font-size: 22px;
    font-weight: 600;
    text-align: right;
}