.myTask-wrapper .task-btn {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #FFF;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.myTask-wrapper .task-btn img {
    width: 20px;
    height: 20px;
}

.myTask-wrapper .task-btn span {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    width: 20px;
    height: 20px;
    background-color: #FF091E;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}