.myBids-wrapper .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.myBids-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.40);
    background: #FFF;
}

.myBids-wrapper .box .profile-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.myBids-wrapper .box h2 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.8;
}

.myBids-wrapper .box p {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
}

.myBids-wrapper .box h4 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.myBids-wrapper .box .icon {
    width: 18px;
    height: 18px;
}

.myBids-wrapper .box .coman-btn {
    width: max-content;
    height: 30px;
    border-radius: 6px;
}

.myBids-wrapper .box h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.6;
}

.myBids-wrapper .box .background {
    border-radius: 10px;
    background: #F6F6F6;
}

.myBids-wrapper .box .description {
    padding: 0 !important;
}

.myBids-wrapper .box .description h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.6;
}

.myBids-wrapper .box .description p {
    color: rgba(0, 0, 0, 0.6);
}

.myBids-wrapper .box .price {
    color: #0D7A5F;
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .myBids-wrapper .grid-2 {
        grid-template-columns: 1fr;
    }
}