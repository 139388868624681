.task-price-card {
    display: grid;
    gap: 30px;
}

.task-price-card .card {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #FFF;
    display: grid;
    grid-template-columns: 2fr 5fr;
    height: 100%;
    gap: 20px;
}

.task-price-card .profile-img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
}

.task-price-card h2 {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 600;
}

.task-price-card h6 {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    opacity: 0.6;
}

.task-price-card h1 {
    color: #000;
    font-size: 23px;
    font-weight: 600;
}

.task-price-card h3 {
    color: #0D7A5F;
    font-size: 24px;
    font-weight: 600;
}

.task-price-card .task-time {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background: rgba(13, 122, 95, 0.10);
    padding: 2px 8px;
}

.task-price-card .star-icon {
    height: 16px;
    width: 16px;
}

.task-price-card span {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.task-price-card .task {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
}

.task-price-card h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.6;
}

.task-price-card p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.task-price-card .read-more {
    color: #0D7A5F;
    cursor: pointer;
}

.task-price-card .background {
    border-radius: 10px;
    background: #F6F6F6;
}

.task-price-card .profile-icon {
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    background-color: #F6F6F6;
}

.task-price-card h5 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 992px) {
    .task-price-card .card {
        grid-template-columns: 1fr;
    }
}