.bid-submission-wrapper .bid-submission-from .box {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.25);
}

.bid-submission-wrapper .bid-submission-from label {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.bid-submission-wrapper .main-grid-2 .box {
    height: fit-content !important;
}

@media only screen and (max-width: 1200px) {
    .bid-submission-wrapper .main-grid-2 {
        grid-template-columns: 3fr 5fr;
    }
}

@media only screen and (max-width: 992px) {
    .bid-submission-wrapper .main-grid-2 {
        grid-template-columns: 1fr;
    }
}