.search-wrapper .form {
    padding-inline: 0.8em;
    transition: border-radius 0.5s ease;
    border-radius: 44px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    height: 50px;
    width: 100%;
}

.search-wrapper .input {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    background-color: transparent;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
    width: 100%;
    height: 100%;
}

.search-wrapper .form:focus-within {
    border-radius: 10px 10px 0 0;
}

.search-wrapper .form:focus-within:before {
    transform: scale(1);
}

.search-wrapper .reset {
    border: none;
    background: none;
    opacity: 0;
    visibility: hidden;
}

.search-wrapper input:not(:placeholder-shown)~.reset {
    opacity: 1;
    visibility: visible;
}

.search-wrapper .reset img {
    height: 20px;
    width: 20px;
}

.search-wrapper .filter-btn {
    background: #0D7A5F;
    padding: 12px;
    border-radius: 50%;
    border: none;
    height: 50px;
}

.search-wrapper .search-icon {
    width: 20px;
    height: 20px;
}

.search-wrapper .filter-icon {
    width: 26px;
    height: 26px;
}

.filter h3 {
    color: #000;
    font-size: 24px;
    font-weight: 500;
}