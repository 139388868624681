.bids-card .cards {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.40);
    background: #FFF;
}

.bids-card .profile-img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50%;
}

.bids-card .star-icon {
    height: 13px;
    width: 13px;
}

.bids-card h1 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.8;
}

.bids-card span {
    color: #000;
    font-size: 13.2px;
    font-weight: 500;
}

.bids-card .view-profile {
    color: #0D7A5F;
    font-size: 13px;
    font-weight: 600;
}

.bids-card h5 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
}

.bids-card h3 {
    color: #0D7A5F;
    font-size: 18px;
    font-weight: 600;
}

.bids-card h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.6;
}

.bids-card p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.6;
}

.bids-card .background {
    border-radius: 10px;
    background: #F6F6F6;
}

.bids-card .star-border {
    border: 2px solid #FFC403;
    border-radius: 50%;
    font-size: 22px;
    width: 28px;
    height: 28px;
    line-height: 1;
    text-align: center;
}