.cancelModal h5 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
}

.cancelModal p {
    color: rgba(0, 0, 0, 0.40);
    font-size: 16px;
    font-weight: 400;
}

.my-modal {
    max-width: 620px;
}