.description {
    border-radius: 10px;
    background: #F6F6F6;
}

.description p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    word-break: break-all;
}

.description h4 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    opacity: 0.6;
}

.description p span {
    color: #0D7A5F;
    font-size: 12px;
    font-weight: 600;
    background: none;
}