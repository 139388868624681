.myTask-wrapper .nav-pills .nav-item button {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #FFF;
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
}

.myTask-wrapper .title {
    top: calc(90% - 1.25em);
    left: calc(50% - 1.25em);
}

.myTask-wrapper .nav-pills .nav-item .active {
    border-radius: 10px;
    background: #0D7A5F;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    opacity: 1;
}

.myTask-wrapper .tab-content .grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
}

@media only screen and (max-width: 1200px) {
    .myTask-wrapper .tab-content .grid-3 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 992px) {
    .myTask-wrapper .title {
        position: relative !important;
        top: 0;
        left: 0;
    }
}

@media only screen and (max-width: 768px) {
    .myTask-wrapper .tab-content .grid-3 {
        grid-template-columns: 1fr;
    }
}