.deleteAccModal .modal-content {
    border-radius: 20px;
    border: 1px solid #B00505;
}

.deleteAccModal h1 {
    color: #B00505;
    font-size: 30px;
    font-weight: 600;
}

.deleteAccModal h5 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
}

.deleteAccModal .coman-btn {
    border-color: #B00505;
    color: #B00505;
}

.deleteAccModal .coman-btn-fill {
    border-color: #B00505;
    background-color: #B00505;
}