.bg-box {
    max-width: 784px;
    min-height: 366px;
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.25);
}

.bg-box h1 {
    color: #000;
    font-size: 36px;
    font-weight: 600;
}

.bg-box p {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.6;
}

.group {
    line-height: 28px;
    position: relative;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 10px;
    width: 100%;
    align-items: center;
}

.group button {
    border-radius: 10px;
    background: #0D7A5F;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
}

.input {
    width: 100%;
    height: 50px;
    line-height: 28px;
    padding: 0 1rem;
    padding-left: 2.5rem;
    outline: none;
    color: #0d0c22;
    transition: .3s ease;
    border-radius: 10px;
    border: 1px solid rgba(13, 122, 95, 0.20);
    background: #F6F6F6;
}

.input::placeholder {
    color: #9e9ea7;
}

.bg-banner {
    background-image: url("../assets/images/landingImg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-banner .icon {
    position: absolute;
    left: 10px;
    top: 26px;
    width: 24px !important;
    height: 24px !important;
}

.bg-banner .close-icon {
    position: absolute;
    right: 10px;
    top: 12px;
    width: 24px !important;
    height: 24px !important;
}

.bg-banner .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.bg-box-btn {
    border-radius: 10px;
    background: rgba(13, 122, 95, 0.10);
    display: inline-flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    border: 0;
    margin-right: 10px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.landing_page h2 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
}

.landing_page .h4 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
}

/* service section  */
.service-section p,
.service-section li {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    opacity: 0.6;
}

.service-section li {
    opacity: 1;
    background-image: url('../assets//images/currect.png');
    list-style: none;
    padding: 10px 30px;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
}


/* testimonial section  */
.testimonial-section h6 {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    opacity: 0.6;
}

.background-image {
    height: calc(100vh - 70px);
    margin-top: 70px;
}


@media only screen and (max-width: 768px) {
    .group {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 576px) {

    .service-section p {
        padding-right: 0 !important;
    }
}