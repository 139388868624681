.testimonial-box {
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.15);
    scale: 0.8;
    transition: all 1s;
}

.blur::before {
    content: '';
    height: 100%;
    width: 35%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 65.62%);
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
}

.blur::after {
    content: '';
    height: 100%;
    width: 35%;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.00) 0%, #FFF 65.62%);
    z-index: 10;
    position: absolute;
    top: 0;
    right: -2px;
    transform: rotate(180deg);
}

.blur {
    border-radius: 30px;
}

.blur .swiper-slide-active {
    scale: 1.1;
}

.testimonial-box .icon {
    height: 17px;
    width: 17px;
}

.testimonial-box .profile {
    width: 84px;
    height: 84px;
}

.testimonial-box h3 {
    color: #0D7A5F;
    font-size: 24px;
    font-weight: 600;
}


@media only screen and (max-width: 1199px) {

    .blur::after,
    .blur::before {
        display: none;
    }

    .testimonial-box {
        transition: all 0.3s;
    }
}