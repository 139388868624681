.task-card-wrapper .box {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
}

.task-card-wrapper .custome-grids {
    display: grid;
    grid-template-columns: 45px 1fr;
    gap: 10px;
}

.task-card-wrapper .profile-img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}

.task-card-wrapper .icon {
    height: 18px;
    width: 18px;
}

.task-card-wrapper h3 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.task-card-wrapper h4 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.6;
}

.task-card-wrapper h5 {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.6;
}

.task-card-wrapper h2 {
    color: #0D7A5F;
    font-size: 16px;
    font-weight: 500;
}

.task-card-wrapper p {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    word-break: break-all;
}

.task-card-wrapper .icons {
    width: 20px;
    height: 20px;
}

.task-card-wrapper .title {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.task-card-wrapper .desc {
    overflow: hidden;
    display: -webkit-box;
    line-height: 1.4;
    max-height: 85px;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}