.detailModal h3 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.detailModal h2 {
    color: #0D7A5F;
    font-size: 22px;
    font-weight: 600;
}

.detailModal h1 {
    color: #000;
    font-size: 25px;
    font-weight: 600;
}

.detailModal .profile-img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.detailModal h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    opacity: 0.6;
    margin-bottom: 0;
}

.detailModal p {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.detailModal .description {
    border-radius: 10px;
    background: #F6F6F6;
}

.detailModal .description p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}

.detailModal .coman-btn-fill span {
    color: rgba(255, 255, 255, 0.80);
    font-size: 16px;
    font-weight: 500;
}

.detailModal .delete-btn {
    color: #DB1829;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.detailModal .btn-close:focus {
    box-shadow: none;
}

.detailModal .read-more {
    color: #0D7A5F;
    background: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
}

.detailModal .p-text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}