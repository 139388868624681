.addamountmodal .modal-content {
    padding: 20px 50px;
}

.addamountmodal label {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.addamountmodal .din span {
    right: 20px;
    top: 13px;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

@media only screen and (max-width: 576px) {
    .addamountmodal .modal-content {
        padding: 10px;
    }
}