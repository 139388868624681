.pagination-two-wrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.pagination-two-wrapper span {
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.pagination-two-wrapper .active {
    background-color: #0D7A5F;
    color: #FFF;
}

.pagination-two-wrapper .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.pagination-two-wrapper .disabled {
    opacity: 0.3;
}

.provider-pagination .page-link {
    border: none;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.5);
    height: 30px;
    width: 30px;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
}

.provider-pagination .active>.page-link {
    background-color: #0D7A5F;
    color: #fff;
}

.provider-pagination .disabled {
    opacity: 0.3;
}