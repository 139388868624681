.profile-creation-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.profile-creation-wrapper label {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.profile-creation-wrapper span {
    right: 20px;
    top: 12px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.profile-creation-wrapper h3 {
    color: #0D7A5F;
    font-size: 18px;
    font-weight: 500;
}

.profile-creation-wrapper .delete-icon {
    background: transparent;
    border: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 55px;
    top: 15px;
}

.profile-creation-wrapper .edit-icon {
    background: transparent;
    border: none;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 20px;
    top: 15px;
}