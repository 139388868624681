.message-wrapper .chat {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 15px;
}

.message-wrapper .chat-left,
.message-wrapper .chat-right {
    border-radius: 20px;
    border: 1px solid #0D7A5F;
    background: #FFF;
    min-height: 555px;
}

.message-wrapper .chat-left {
    overflow-y: scroll;
    max-height: 677px;
}

.message-wrapper .chat-left::-webkit-scrollbar {
    width: 0px;
}

.message-wrapper .chat-left .chats {
    cursor: pointer;
}

.message-wrapper .chat-left .chats .profile-picture {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.message-wrapper .chat-left .chats h1 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
}

.message-wrapper .chat-left .chats h1 .service-name {
    color: #0D7A5F;
    font-size: 14px;
    font-weight: 400;
}

.message-wrapper .chat-left .chats .read {
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    line-height: 1.4;
    height: 24px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.message-wrapper .chat-left .chats .unread {
    color: #0D7A5F;
}

.message-wrapper .chat-left .chats h6 {
    color: #000;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.6;
    width: 55px;
}

.message-wrapper .chat-left .chats .badge {
    width: 20px;
    height: 20px;
    background: #0D7A5F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 12px;
    font-weight: 600;
}

.message-wrapper .chat-right .header {
    border-bottom: 1px solid rgba(13, 122, 95, 0.2);
}

.message-wrapper .chat-right .header .profile-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
}

.message-wrapper .chat-right .header h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    opacity: 0.9;
}

.message-wrapper .chat-right .header h4 {
    color: #0D7A5F;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
    padding: 5px;
}

.message-wrapper .chat-right .header .icon {
    width: 24px;
    height: 24px;
}

.message-wrapper .chat-right .body .msg-box-send .msg-text {
    border-radius: 12px 12px 0px 12px;
    background: #0D7A5F;
    max-width: 302px;
    padding: 8px;
    color: #fff;
}

.message-wrapper .chat-right .body .msg-box-send .msg-text p {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
}

.message-wrapper .chat-right .body .msg-time {
    color: #000;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.6;
}

.message-wrapper .chat-right .body .messages-content {
    height: 500px;
    overflow-y: scroll;
    margin-bottom: 60px;
}

.message-wrapper .chat-right .body .msg-text {
    width: 100%;
}

.message-wrapper .chat-right .body .messages-content::-webkit-scrollbar {
    width: 0px;
}


.message-wrapper .chat-right .body .messages-content .right-side-chat {
    border-radius: 12px 12px 0px 12px;
    background: #0D7A5F;
    color: #fff;
    max-width: 302px;
    padding: 8px;
    animation: pop 0.3s forwards;
}

.message-wrapper .chat-right .body .messages-content .left-side-chat {
    border-radius: 12px 12px 12px 0px;
    background: #F2F2F2;
    max-width: 302px;
    padding: 8px;
    animation: pop 0.3s forwards;
}

.message-wrapper .chat-right .body .messages-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.message-wrapper .chat-right .body .message-chat-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
}

.message-wrapper .chat-right .body .message-chat-start {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;
}

.message-wrapper .chat-right .body .time-stamp {
    color: #000;
    font-size: 12px;
    font-weight: 400;
    animation: pop 0.3s forwards;
    opacity: 0.8;
}


.message-wrapper .chat-right .body .messages-content .receiver_message {
    word-break: break-word;
}

.message-wrapper .chat-right .body .msg-text p {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    opacity: 0.8;
}

.message-wrapper .chat-right .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.message-wrapper .chat-right .footer input {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #FFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 50px;
    padding-left: 20px;
    padding-right: 50px;
}

.message-wrapper .chat-right .footer .send {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: 12px;
}


@media only screen and (max-width: 992px) {
    .message-wrapper .chat {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width:768px) and (max-width:992px) {
    .message-wrapper .chat-right .header h1 {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        opacity: 0.9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
    }
}

@media only screen and (max-width: 768px) {
    .message-wrapper .chat {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 576px) {

    .message-wrapper .chat-right .header h1 {
        color: #000;
        font-size: 20px;
        font-weight: 600;
        opacity: 0.9;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 96px;
    }

    .message-wrapper .chat-right .body .messages-content {
        margin-bottom: 80px;
    }
}







/*********************** Modal calender ***************************/

.calender-modal .grid-2 {
    display: grid;
    grid-template-columns: 4fr 3fr;
}

.calender-modal .person-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.calender-modal h3 {
    color: #000;
    font-size: 26px;
    font-weight: 600;
}

.calender-modal h6 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.calender-modal p {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    opacity: 0.6;
}

.calender-modal h5 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    opacity: 0.6;
}

.calender-modal h4 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 19px;
}

.calender-modal .left {
    border-right: 1px solid rgb(0, 0, 0, 0.2);
}

.calender-modal .react-calendar {
    border: none;
    width: 100%;
}

.calender-modal .react-calendar__tile--active {
    background: #0D7A5F !important;
    border-radius: 10px;
    color: #fff;
}

.calender-modal .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #0D7A5F;
}

.calender-modal .react-calendar__tile--now {
    background: rgb(13, 122, 95, 0.5);
    border-radius: 10px;
}

.calender-modal .react-calendar__navigation__arrow {
    display: none;
}

.calender-modal .react-calendar__navigation__label span {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.no-user-selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 10px;
}

.no-user-selected h1 {
    color: #000;
    font-size: 22px;
    font-weight: 600;
}

.no-user-selected h4 {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}

.no-user-selected img {
    width: 246.199px;
    height: 185.799px;
    margin-bottom: 20px;
}



@keyframes pop {
    0% {
        transform: scale(0.6);
    }

    30% {
        transform: scale(1.1);
    }

    60% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.date-header {
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    color: #777;
}

@media only screen and (max-width: 992px) {
    .calender-modal .left {
        border-right: 0;
    }
}