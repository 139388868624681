.name-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
}

.name-loader .box {
    width: 20px;
    height: 20px;
    margin: 0 8px;
    border-radius: 50%;
    animation: jump_4123 1s ease-in-out infinite;
}

.name-loader .box:nth-child(1) {
    background-color: #d7d7c3;
    animation-delay: 0.2s;
}

.name-loader .box:nth-child(2) {
    background-color: #0D7A5F;
    animation-delay: 0.4s;
}

.name-loader .box:nth-child(3) {
    background-color: #d7d7c3;
    animation-delay: 0.6s;
}

.name-loader .box:nth-child(4) {
    background-color: #0D7A5F;
    animation-delay: 0.8s;
}

@keyframes jump_4123 {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }
}