.arrow {
    height: 24px;
    width: 24px;
    top: 50%;
    left: -30%;
    position: absolute;
    border-radius: 50%;
    object-fit: contain;
    outline-offset: 5px;
    outline: 0.5px solid rgba(15, 44, 150, 0.7);
}

.plumbing-container .form {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
    line-height: 3.5;
}

.plumbing-container .form label {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.plumbing-container .form select {
    height: 50px;
}


.plumbing-container .suggestions-container {
    background: #f2f2f2;
    border-radius: 10px;
    overflow-y: auto;
    cursor: pointer;
    color: #000;
}

.plumbing-container .suggestion-item {
    cursor: pointer;
}

.plumbing-container .suggestion-item:hover,
.plumbing-container .suggestion-item:focus,
.plumbing-container .suggestion-item.selected {
    background-color: #D3D6DB;
}

.plumbing-container .suggestion-text {
    font-size: 14px;
    color: #333;
    padding-left: 10px;
}

.plumbing-container .suggestions-container::-webkit-scrollbar {
    width: 5px;
}

.plumbing-container .suggestions-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.plumbing-container .suggestions-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.custom-horizontal-stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

:root {
    --step-1-content: 'Describe Your Task';
    --step-2-content: 'Browse Taskers & prices';
    --step-3-content: 'Choose Date & Time';
    --step-4-content: 'Choose Payment';
    --step-5-content: 'Confirm Details';
}

.custom-horizontal-stepper .step:nth-child(1)::after,
.custom-horizontal-stepper .step:nth-child(2)::after,
.custom-horizontal-stepper .step:nth-child(3)::after,
.custom-horizontal-stepper .step:nth-child(4)::after,
.custom-horizontal-stepper .step:nth-child(5)::after {
    content: var(--step-1-content);
    position: absolute;
    top: 120%;
    color: rgba(0, 0, 0, 0.6);
    width: 140px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
}

.custom-horizontal-stepper .step:nth-child(2)::after {
    content: var(--step-2-content);
}

.custom-horizontal-stepper .step:nth-child(3)::after {
    content: var(--step-3-content);
}

.custom-horizontal-stepper .step:nth-child(4)::after {
    content: var(--step-4-content);
}

.custom-horizontal-stepper .step:nth-child(5)::after {
    content: var(--step-5-content);
}

.step {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #d7d7c3;
    display: grid;
    place-items: center;
    color: #000;
    position: relative;
    cursor: pointer;
}

.progress-bar {
    position: absolute;
    height: 3px;
    width: 100%;
    background: #D9D9D9;
    z-index: -1;
}

.progress-bar .indicatore {
    width: 100%;
    height: 100%;
    position: absolute;
}

.green-progress-bar {
    background-color: green;
}

.green-progress-25 {
    width: 25%;
    background-color: green;
}

.green-progress-50 {
    width: 50%;
    background-color: green;
}

.green-progress-75 {
    width: 75%;
    background-color: green;
}

.green-progress-100 {
    width: 100%;
    background-color: green;
}

.progress-bar-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #D6D6D6;
    text-align: center;
    white-space: nowrap;
    background-color: #D6D6D6;
    transition: all ease 0.3s;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #D9D9D9;
    z-index: -2;
}


.step.active {
    background-color: #0D7A5F;
    color: #fff;
    border-radius: 50%;
}

.step.active::after {
    color: #0D7A5F !important;
}

.step.completed::after {
    color: #0D7A5F !important;
}

.step.completed {
    background-color: #0D7A5F;
    color: #fff;
    border-radius: 50%;
}


.task-price-container h2 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.task-price-container .search-bars img {
    height: 20px;
    width: 20px;
    top: 9px;
    left: 11px;
    opacity: 0.5;
}

.task-price-container .search-bars input {
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    padding: 6px 10px 6px 40px;
}

.main-grid-2 {
    display: grid;
    grid-template-columns: 2fr 5fr;
    gap: 30px;
}

.task-price-container .sorted,
.date-time-container .sorted {
    border-radius: 10px;
    border: 1px solid #0D7A5F !important;
    min-height: 30px !important;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    padding: 7px 10px;
    cursor: pointer;
}

.task-price-left {
    border-radius: 20px;
    border: 1px solid var(--green, #0D7A5F);
    background: #FFF;
    padding: 20px;
    height: fit-content;
    position: relative;
}

.task-price-left .react-calendar {
    position: absolute;
    left: 156px;
    top: 140px;
    z-index: 100;
}

.task-price-left h3 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.task-price-left .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.task-price-left .button {
    min-height: 38px;
    color: #000;
    text-align: center;
    border-radius: 10px;
    background: rgba(13, 122, 95, 0.10);
    border: none;
}

.task-price-left .button:focus {
    background: #0D7A5F;
    color: #fff;
}

.form-check-input:checked {
    background-color: #0D7A5F;
    border-color: #0D7A5F;
}

.form-check-input:focus {
    box-shadow: none;
}

.task-price-left label {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.horizontal-slider {
    width: 100%;
    height: 50px;
}

.horizontal-slider .example-thumb {
    top: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    color: transparent;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../assets/images/dot.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.horizontal-slider .example-thumb:focus {
    outline: none;
}

.horizontal-slider .example-track {
    height: 10px;
    top: 20px;
    border-radius: 10px;
}

.example-track.example-track-0 {
    background: #D6D6D6;
}

.example-track.example-track-1 {
    background: #0D7A5F;
}

.example-track.example-track-2 {
    background: #D6D6D6;
}

.task-price-left .hr-text p {
    background-color: #fff;
    position: absolute;
    top: -9px;
    padding: 0 6px;
    left: 50%;
    transform: translate(-50%, 0px);
    z-index: 1;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 600;
    white-space: nowrap;
}

.task-price-left h4 {
    color: rgba(35, 35, 35, 0.70);
    font-size: 15px;
    font-weight: 400;
}

.task-price-left h4 span {
    color: #232323;
    font-size: 16px;
    font-weight: 600;
}

.date-time-container {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.25);
}

/* .date-time-container .grid-2 {
    display: grid;
    grid-template-columns: 4fr 3fr;
} */

.date-time-container .person-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.date-time-container h3 {
    color: #000;
    font-size: 26px;
    font-weight: 600;
}

.date-time-container h6 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.date-time-container p {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 19px;
    opacity: 0.6;
}

.date-time-container h5 {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    line-height: 19px;
    opacity: 0.6;
}

.date-time-container h4 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 19px;
}

.date-time-container .left {
    border-right: 1px solid rgb(0, 0, 0, 0.3);
}

.date-time-container .react-calendar {
    border: none;
    width: 100%;
}

.date-time-container .react-calendar__tile--active {
    background: #0D7A5F !important;
    border-radius: 10px;
    color: #fff;
}

.date-time-container .react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #0D7A5F;
}

.date-time-container .react-calendar__tile--now {
    background: rgb(13, 122, 95, 0.5);
    border-radius: 10px;
}

.date-time-container .react-calendar__navigation__arrow {
    display: none;
}

.date-time-container .react-calendar__navigation__label span {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.confirm-detail-container .heading {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.6;
}

.task-price-container .pagination .page-link {
    z-index: 3;
    background-color: none;
    border: none;
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    font-weight: 500;
    border-radius: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.task-price-container .pagination .active .page-link {
    color: #fff;
    background-color: #0D7A5F;
}

@media only screen and (max-width: 1200px) {
    .task-price-left .grid-2 {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .custom-horizontal-stepper .step:nth-child(1)::after,
    .custom-horizontal-stepper .step:nth-child(2)::after,
    .custom-horizontal-stepper .step:nth-child(3)::after,
    .custom-horizontal-stepper .step:nth-child(4)::after,
    .custom-horizontal-stepper .step:nth-child(5)::after {
        content: '';
    }
}

@media only screen and (max-width: 992px) {
    .main-grid-2 {
        grid-template-columns: 2fr 3fr;
    }
}

@media only screen and (max-width: 768px) {
    .main-grid-2 {
        grid-template-columns: 1fr;
    }

    /* .date-time-container .grid-2 {
        grid-template-columns: 1fr;
    } */

    .date-time-container .left {
        border: none;
    }
}