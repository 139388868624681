.loader-container {
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-container:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(0, 0, 0, .6), rgba(0, 0, 0, .6));
}

.loader-container .loader {
    position: relative;
    margin: 0 auto;
    width: 80px;
}

.loader-container .loader-text {
    position: absolute;
    top: 50%;
    left: 56%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.loader-container .loader-text svg {
    height: 50px;
    width: 50px;
}

.loader-container .loader:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.loader-container .circular {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 90px;
    width: 90px;
}

.loader-container .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

.loader-container .showbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5%;
}

@-webkit-keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@-webkit-keyframes color {

    100%,
    0% {
        stroke: #fff;
    }

    40% {
        stroke: #fff;
    }

    66% {
        stroke: #fff;
    }

    80%,
    90% {
        stroke: #fff;
    }
}

@keyframes color {

    100%,
    0% {
        stroke: #fff;
    }

    40% {
        stroke: #fff;
    }

    66% {
        stroke: #fff;
    }

    80%,
    90% {
        stroke: #fff;
    }
}