.logoutModal .modal-content {
    border-radius: 20px;
    border: 1px solid #0D7A5F;
}

.logoutModal h1 {
    color: #000;
    font-size: 30px;
    font-weight: 600;
}

.logoutModal h5 {
    color: rgba(0, 0, 0, 0.60);
    font-size: 20px;
    font-weight: 400;
    line-height: 22px;
}