.task-posting-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.task-posting-wrapper .form-group label {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.task-posting-wrapper .price {
    border-radius: 10px;
    border: 1px solid rgba(13, 122, 95, 0.20);
    background: #FFF;
    height: 50px;
}

.task-posting-wrapper input[type="radio"] {
    accent-color: #0D7A5F;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.task-posting-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
    background: url('../../../assets/images/Calendar.png') no-repeat;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
}

.task-posting-wrapper input[type="time"]::-webkit-calendar-picker-indicator {
    background: url('../../../assets/images/Time.png') no-repeat;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
}


.task-posting-wrapper .suggestions-container {
    background: #f2f2f2;
    border-radius: 10px;
    overflow-y: auto;
    cursor: pointer;
    color: #000;
}

.task-posting-wrapper .suggestion-item {
    cursor: pointer;
    padding: 10px 0;
}

.task-posting-wrapper .suggestion-item:hover,
.task-posting-wrapper .suggestion-item:focus,
.task-posting-wrapper .suggestion-item.selected {
    background-color: #D3D6DB;
}

.task-posting-wrapper .suggestion-text {
    font-size: 14px;
    color: #333;
    padding-left: 10px;
}

.task-posting-wrapper .suggestions-container::-webkit-scrollbar,
.rc-time-picker-panel-select::-webkit-scrollbar {
    width: 5px;
}

.task-posting-wrapper .suggestions-container::-webkit-scrollbar-track,
.rc-time-picker-panel-select::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.task-posting-wrapper .suggestions-container::-webkit-scrollbar-thumb,
.rc-time-picker-panel-select::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 10px;
}

.rc-time-picker-panel {
    width: fit-content;
}

.rc-time-picker-panel-select {
    font-size: 18px;
    width: 80px;
}

.task-posting-wrapper .rc-time-picker {
    display: block;
}

.task-posting-wrapper .rc-time-picker-input {
    border-radius: 10px;
    border: 2px solid rgba(214, 214, 214, 0.90) !important;
    min-height: 50px;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.7;
    color: #000;
}

.task-posting-wrapper .rc-time-picker-clear {
    display: none;
}

.task-posting-wrapper .date-picker::-webkit-calendar-picker-indicator {
    font-size: 2rem;
}

.task-posting-wrapper .react-datepicker-wrapper {
    width: 100%;
}