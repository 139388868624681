.bank-details-wrapper .box {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
    max-width: 650px;
}

.bank-details-wrapper .box p {
    color: #151515;
    font-size: 20px;
    font-weight: 500;
    word-break: break-all;
}

.bank-details-wrapper .box .icon {
    width: 24px;
    height: 24px;
}

.bank-details-wrapper .box-2 {
    border-radius: 20px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.bank-details-wrapper .box-2 label {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}