.booktask-section .box {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 34px 0px rgba(0, 0, 0, 0.25);
}

.booktask-section .box .inner-box {
    max-width: 800px;
}

.booktask-section .icon {
    position: absolute;
    left: 10px;
    top: 12px;
    width: 24px !important;
    height: 24px !important;
}

.booktask-section .box .button-wrapper .button {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #FFF;
    color: #0D7A5F;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 10px;
}

.booktask-section .box .show-more-button {
    border-radius: 10px;
    border: 1px solid #0D7A5F;
    background: #0D7A5F;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 5px 10px;
}

.post-task-section .blank .task-posting-tag {
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.booktask-section .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.booktask-section .css-1u7vgix-control:focus {
    outline: none !important;
    /* border: none; */
}