.reviews-wrapper {
    border-radius: 10px;
    background: #F6F6F6;
}

.reviews-wrapper .profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

.reviews-wrapper h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.reviews-wrapper span {
    color: #0D7A5F;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
    border: 1px solid rgba(13, 122, 95, 0.90);
    background: #F5F7F6;
}

.reviews-wrapper h5 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.reviews-wrapper p {
    color: rgba(0, 0, 0, 0.6);
    ;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.reviews-wrapper h6 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-weight: 400;
}

.reviews-wrapper .star {
    width: 15px;
    height: 15px;
}

.reviews-rate .arrow-page {
    width: 24px;
    height: 24px;
}

.reviews-rate .pagination-btn {
    width: 30px;
    height: 30px;
    border: none;
    background: #fff;
    color: rgba(0, 0, 0, 0.6);
    font-size: 20px;
    font-weight: 500;
    border-radius: 5px;
}

.reviews-rate .active {
    background: #0D7A5F;
    color: #fff;
}

.reviews-rate .pagination {
    justify-content: center;
    gap: 10px;
}

.reviews-rate .read-more {
    border: none;
    font-size: 16px;
    cursor: pointer;
}