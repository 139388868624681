.featured-box {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.15);
    height: auto !important;
}

.featured-box h3 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.featured-box h6 {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.featured-box h6 span {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.6;
}

.featured-box h5 {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.6;
}

.featured-box h4 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.featured-box p {
    color: #000;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    opacity: 0.6;
}

.featured-header .icon {
    width: 16px;
    height: 16px;
}

.featured-header .profile {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.mySwiper {
    border-radius: 20px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper .swiper-button-next {
    background-image: url('../../../assets/images/slider-right.png') !important;
    height: 24px;
    width: 24px;
    background-size: contain;
    outline: 1px solid rgba(13, 122, 95, 0.60);
    border-radius: 50%;
    outline-offset: 5px;
    z-index: 99;
}

.mySwiper .swiper-button-next::after {
    display: none;
}

.mySwiper .swiper-button-prev {
    background-image: url('../../../assets/images/slider-left.png') !important;
    height: 24px;
    width: 24px;
    background-size: contain;
    outline: 1px solid rgba(13, 122, 95, 0.60);
    border-radius: 50%;
    outline-offset: 5px;
    z-index: 99;
}

.mySwiper .swiper-button-prev::after {
    display: none;
}